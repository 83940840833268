/**
 * Application root page
 */
export const ApplicationRoot = '/d/0-0000000/embrace-metrics-api-dashboard';

/**
 * Application
 */
export const ApplicationName = 'Embrace';

